// --------------------------------------------------
// User Dashborad
// --------------------------------------------------
.dashboard {

  [data-role="replies"] {
    padding-left: 25px;

    .cc-f-wrap {
      transform: scale(.8);
    }
  }

  [data-tooltip] {
    cursor: pointer;
  }

  .alert-message {
    background: #da5b47;

    p {
      @extend .site-wrapper;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      color: #fff;
    }
  }

  .js-nav-zone {
    // display: none;

    position: relative;
    top: 0;
    left: 0;
    height: 0;
    opacity: 0;

    overflow: hidden;

    z-index: -1;

    transition: all 0.65s ease;

    &.is-active {
      // display: block;

      z-index: 2;
      opacity: 1;
      height: auto;
    }
  }

  .hide-for-mobile {

    @media (max-width: 914px) {
      display: none;
    }
  }

  .show-only-mobile {
    display: none;

    @media (max-width: 914px) {
      display: block;
    }
  }

  // --------------------------------------------------
  // Hero
  // --------------------------------------------------
  .user-hero {
    position: relative;
    height: auto;
    min-height: 475px;
    margin-bottom: 5px;
    background: url('https://images.pexels.com/photos/313715/pexels-photo-313715.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    background-color: $light;

    overflow: hidden;

    border-bottom: 10px solid #000;

    @media only screen and (max-width: 1109px) {
      margin-bottom: 0;
      border-bottom: none;
    }

    @media only screen and (max-width: 914px) {
      height: 55vw;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($colorAccent1, .55);
      z-index: 1;
    }

    .hero-title {
      position: absolute;
      top: 35%;
      left: 0;
      width: 100%;
      transform: translate3d(0, -50%, 0);
      text-align: center;
      font-family: $fontHand;
      font-weight: 100;
      font-size: 70px;
      letter-spacing: 2px;
      color: #fff;
      padding: 0;
      margin: 0;
      line-height: 1;
      z-index: 2;

      @media only screen and (max-width: 914px) {
        font-size: 45px;
      }
    }

    .countdown-timer {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 425px;
      text-align: right;
      color: #fff;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.15+100 */
      background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.15) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.15) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.15) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      padding: 20px;
      margin: 0;
      line-height: 1;
      z-index: 2;

      font-weight: 200;
      font-size: 14px;
      letter-spacing: 0.025em;

      span {
        font-weight: 600;
        font-size: 24px;
      }

      @media only screen and (max-width: 1109px) {
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
        bottom: auto;
        width: 100%;
        height: auto;
        background: $colorAccent1;
      }

      @media only screen and (max-width: 599px) {
        span {
          font-size: 16px;
        }
      }
    }

    .logo {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate3d(0, -50%, 0);
      z-index: 2;

      @media only screen and (max-width: 1109px) {
        position: absolute;
        top: 10px;
        left: 20px;
        right: auto;
        bottom: auto;
        z-index: 5;
        width: 33px;
        height: auto;
        transform: none;
        padding: 0;
      }

      @media only screen and (max-width: 599px) {
        width: 28px;
      }
    }

    .infos-zone {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      bottom: 0;
      width: 35%;
      height: 100%;

      &:before {
        content: '';

        position: absolute;
        top: 0;
        right: -50%;
        bottom: -50%;
        left: -50%;

        background: $colorAccent1;
        border: 1px solid rgba(255, 255, 255, .25);
        box-shadow: 5px 5px 25px 25px rgba(0, 0, 0, .15);

        transform: translate3d(30%, 0, 0) rotate(-55deg);
      }

      @media only screen and (max-width: 1109px) {
        position: relative;
        top: 0;
        left: 0;
        right: auto;
        bottom: auto;
        width: 100%;
        height: auto;
      }
    }
  }

  // --------------------------------------------------
  // Dashboard
  // --------------------------------------------------
  .section-dashboard {
    position: relative;
    padding-top: 0;

    .site-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media only screen and (max-width: 914px) {
        flex-wrap: wrap;
      }
    }
  }

  .dashboard-posts {
    position: relative;
    width: 60%;
    margin: 0 auto;

    @media only screen and (max-width: 914px) {
      width: 100%;
    }

    .dashboard-post-card {
      margin: 0 0 35px 0;
    }
  }


  // --------------------------------------------------
  // dashboard Aside
  // --------------------------------------------------
  .dashboard-aside {
    position: relative;
    width: 40%;
    transform: translate3d(0, -155px, 0);
    margin-bottom: -155px;

    z-index: 2;

    @media only screen and (max-width: 914px) {
      width: 100%;
    }

    @media only screen and (max-width: 914px) {
      &.show-only-mobile {
        transform: translate3d(0, 0, 0);
        margin-bottom: 0;
      }
    }

    .dashboard-aside-box {
      width: calc(100% - 35px);
      margin-right: 35px;
      margin-bottom: 35px;
      // border: 1px solid $lighter;
      border-radius: 6px;
      background: #fff;
    }

    .profile-box {
      position: relative;
      // border: 1px solid $lighter;
      border-radius: 6px;
      background: #fff;
      text-align: center;

      overflow: hidden;

      box-shadow: 0 8px 24px 0 rgba(0, 0, 0, .12);

      .profile-box-header {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba($colorAccent1, .55);
          z-index: 1;
        }


        img {
          display: block;
          width: 100%;
        }

        .profile-box-text {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translate3d(0, -50%, 0);
          margin-top: -25px;
          text-align: center;
          color: #fff;

          z-index: 2;
        }
      }

      .profile-box-content {
        padding: 55px 35px;
      }

      .profile-box-img {
        position: absolute;
        bottom: 0;
        left: 50;
        transform: translate3d(-50%, 50%, 0);

        z-index: 2;

        img {
          display: inline-block;
          width: 110px;
          height: 110px;
          border-radius: 50%;
          box-shadow: 0 2px 9px 0 rgba(148, 158, 163, .57);
          background: #fff;
        }
      }

      .profile-name {
        margin: 0 0 0 0;
        padding: 10px 0 10px 0;
        color: #fff;
        font-size: 21px;
        font-size: 600;
        letter-spacing: 0.02em;
        max-width: 60%;
        margin: 0 auto;
      }

      .profile-subtitle {
        margin: 0 0 0 0;
        padding: 5px 0 0 0;
        font-size: 16px;
        color: #fff;
      }

      .profile-box-stats {
        display: flex;

        .profile-box-stat {
          width: 100%;
          padding: 25px 0;
          border-top: 1px solid $lighter;
          border-right: 1px solid $lighter;

          span {
            display: inline-block;
            padding-bottom: 10px;
            color: $colorAccent1;
            font-weight: 600;
            font-size: 24px;
          }

          h4 {
            margin: 0;
            padding: 0;
            font-weight: 500;
            color: $light;
            font-size: 12px;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    .map-box {

      #map {
        width: 100%;
        height: 275px;
        background-color: #e8e8e8;
        border-bottom: 1px solid $lighter;
      }

      .map-box-link {
        display: flex;
        align-items: stretch;
        border-bottom: 1px solid $lighter;

        &:last-child {
          border-bottom: 0;
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 75px;
          min-width: 75px;
          max-width: 75px;
          height: 80px;
          border-right: 1px solid $lighter;
          // color: #4A5161;
          font-size: 28px;
        }

        .link-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          width: 100%;
          padding: 10px 25px;
          text-align: left;

          strong {
            display: inline-block;
            padding: 4px 0;
            color: #4A5560;
          }

          p {
            margin: 0;
            padding: 4px 0;
            font-size: 14px;
            font-weight: 400;
            color: $light;
          }
        }
      }
    }

    .ads-ph {
      display: block;
      width: calc(100% - 35px);
      margin-right: 35px;
      margin-bottom: 35px;
      height: auto;
    }

    @media only screen and (max-width: 1109px) {
      .dashboard-aside-box,
      .ads-ph {
        width: calc(100% - 20px);
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }

    @media only screen and (max-width: 914px) {
      .dashboard-aside-box,
      .ads-ph {
        width: calc(100%);
        margin-left: 0;
      }
    }
  }

  // --------------------------------------------------
  // dashboard posts
  // --------------------------------------------------
  .section-dashboard {
    background: #fff;
  }

  // --------------------------------------------------
  // Dashboard Actions
  // --------------------------------------------------
  .dashboard-actions {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    margin-bottom: 35px;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #4A5560;

    .icon {
      margin-right: 10px;
      font-size: 20px;
    }

    .action-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-right: 1px solid #4A5560;
      background: #646D82;
      color: #fff;
      font-weight: 400;
      letter-spacing: 0.02em;
      padding: 25px;

      opacity: 1;
      transition: all 0.35s ease;

      &:hover,
      &.is-active {
        opacity: 0.7;
      }

      &.is-active {
        background: $colorAccent1;
        color: #fff;
        font-weight: 800;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  // --------------------------------------------------
  // Dashboard Cards posts
  // --------------------------------------------------
  .dashboard-post {
    border-radius: 6px;
    background: #fff;

    a {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      color: $colorAccent1;
    }

    .img-social {
      max-width: 120px;
      margin: 0 auto;
    }

    .dashboard-post-header {
      padding: 25px;
      padding-bottom: 0;
      line-height: 1.5;
      // border-bottom: 1px solid $lighter;

      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }

      .post-title {
        text-align: center;
      }
    }

    .dashboard-post-content {
      padding: 0 25px 25px 25px;
      line-height: 1.5;
      text-align: left;

      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .post-read-more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 25px auto;
      position: relative;
      font-size: 12px;
      font-weight: 600;
      color: #A0ACB9;
      letter-spacing: 0.04em;
      text-align: center;

      a {
        color: inherit;
      }

      &:before {
        position: absolute;
        content: '';
        height: 1px;
        left: 0;
        right: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        backface-visibility: hidden;
        filter: blur(0);
        background: $lighter;
      }

      span {
        position: relative;
        padding: 25px;
        background: #fff;

        z-index: 2;
      }
    }

    .social-row {
      padding-top: 20px;
      border-bottom: 1px solid $lighter;
      margin-bottom: 25px ;
    }

    .site-btn {
      background: $colorAccent1;
      color: #fff;
      margin-bottom: 25px;
    }
  }

  // --------------------------------------------------
  // Dashboard links
  // --------------------------------------------------
  .i-plus {
      position: absolute;
      top: 20px;
      right: 20px;
      display: none;
      transform: scale(.8);

      &:before,
      &:after {
        background: #5F5D5D;
      }

      @media (max-width: 565px) {
        display: block;
      }
  }

  .filter-title {
    font-size: 18px;
    margin-bottom: 20px;
    color: $dark;
    text-transform: uppercase;

    @media (max-width: 565px) {
      padding: 15px 20px 0 20px;
    }
  }

  .dashboard-links {
    margin-bottom: 60px;

    @media (max-width: 565px) {
      margin-bottom: 0;
    }
  }

  .filter-link {
    display: block;
    font-size: 16px;
    padding-left: 25px;
    margin-bottom: 15px;
    border-left: 4px solid #fff;
    font-weight: 500;
    letter-spacing: 0;
    color: $light;

    transition: all 0.35s ease;

    &:hover,
    &.is-active {
      color: $colorAccent1;
    }

    &.is-active {
      border-color: $colorAccent1;
    }

    &.is-disabled {
      opacity: .35;

      &:hover {
        color: $light;
      }
    }

    @media (max-width: 767px) {
      font-size: 16px;
    }

    @media (max-width: 565px) {
      line-height: 1;
      padding: 15px 20px;
      margin-bottom: 0;
    }
  }

  .block-grid {
    width: 100%;

    ul {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: auto;

      padding: 0;
      margin: 0;

      list-style-type: none;

      @media (max-width: 599px) {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: scroll;
      }

      li.block-grid-card {
        position: relative;
        top: 0;
        width: calc((100% / 3) - 10px);
        padding: 0;
        margin: 0 0 20px 0;

        border: 1px $lighter solid;
        border-radius: 4px;
        overflow: hidden;

        transition: all 0.45s ease;

        cursor: pointer;

        &.row-is-2 {
          width: calc((100% / 2) - 5px);
        }

        @media (min-width: 768px) {
          &:hover {
            top: -4px;
            box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.12);

            .img-wrapper {
              // background-size: auto 145%;
            }
          }
        }

        @media (max-width: 767px) {
          width: calc((100% / 2) - 5px);

          margin-bottom: 10px;
        }

        @media (max-width: 599px) {
          width: 80%;
          min-width: 275px;
          margin-right: 10px;

          margin-bottom: 10px;
        }

        .img-wrapper {
          position: relative;
          width: 100%;
          height: 255px;
          background-position: center;
          background-size: cover;
          background-size: auto 135%;

          // transition: all 2.45s ease;
        }

        img {
          display: block;
          width: 100%;
        }

        .icon {
          position: absolute;
          bottom: 10px;
          right: 10px;
          z-index: 2;
          color: #fff;
          opacity: 0.45;
          font-size: 35px;
          line-height: 1;
          margin: 0;
          padding: 0;
        }

        .grid-card-content {
          padding: 16px;
        }

        h3 {
          padding: 0;
          margin: 0 0 10px 0;
          color: rgb(74, 85, 96);
        }

        .regular-text {
          font-size: 14px;
          font-weight: 400;
          color: rgb(160, 172, 185);
          margin: 0px;
        }
      }
    }
  }
}


// --------------------------------------------------
// Footer
// --------------------------------------------------

.footer-top,
.footer-bottom {
  background: #fff;
  padding: 75px 0 0 0;

  @media only screen and (max-width: 899px) {
    padding: 45px 0 0 0;
  }
}

// --------------------------------------------------
// Newsletter
// --------------------------------------------------

.newsletter {
  padding-bottom: 75px;
  border-top: 5px solid rgba(0, 0, 0, .03);
  border-bottom: 5px solid rgba(0, 0, 0, .03);
  background: $light;

  @media only screen and (max-width: 899px) {
    padding-bottom: 45px;
  }

  .grid-container {
    @media only screen and (max-width: 899px) {
      flex-wrap: wrap;
    }
  }

  .newsletter-heading {
    font-size: 38px;
    color: $dark;
    max-width: 500px;

    @media only screen and (max-width: 899px) {
      text-align: center;
      max-width: inherit;
      font-size: 27px;
      max-width: 475px;
      margin: 0 auto 35px auto;
    }
  }

  .newsletter-form {
    display: flex;
    width: 100%;
    margin-left: auto;

    @media only screen and (max-width: 899px) {
      max-width: 475px;
      margin: 0 auto;
    }

    input {
      width: 80%;
      background: rgba(0, 0, 0, .03);
      border: 5px solid rgba(0, 0, 0, .03);
      color: $dark;
      border-radius: 4px;
      padding: 25px 15px;
      outline: none;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.04em;

      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: none;

      @media only screen and (max-width: 899px) {
        padding: 15px 10px;
        font-size: 16px;
      }
    }

    button {
      @extend .site-btn;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      width: 20%;
      min-width: 130px;
      padding: 25px 15px;
      font-size: 20px;
      font-weight: 400;

      @media only screen and (max-width: 899px) {
        padding: 15px 10px;
        font-size: 16px;
      }
    }
  }


}

// --------------------------------------------------
// Footer Links
// --------------------------------------------------

.footer-links {

  .footer-links-wrapper {
    padding-bottom: 75px;

    @media only screen and (max-width: 899px) {
      padding-bottom: 0;
    }
  }

  .grid-container {
    @media only screen and (max-width: 899px) {
      flex-wrap: wrap;
    }
  }

  .footer-logo {
    display: block;
    width: 100px;
  }

  p {
    max-width: 500px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;

    @media only screen and (max-width: 499px) {
      text-align: center;
    }
  }

  .icons {
    @media only screen and (max-width: 499px) {
      text-align: center;
    }

    .icon {
      font-size: 65px;
      color: $light;

      @media only screen and (max-width: 499px) {
        font-size: 55px;
        margin: 0 10px;
      }
    }
  }

  .footer-links-list {
    display: flex;
    justify-content: space-between;
    padding-left: 50px;

    @media only screen and (max-width: 499px) {
      flex-wrap: wrap;
      padding-left: 0;
    }

    ul {
      list-style-type: none;
      padding: 0 20px;

      flex-grow: 1;

      &:first-child {
        flex-grow: 3;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      @media only screen and (max-width: 499px) {
        text-align: center;
      }

      li {
        color: $light;
        font-weight: 600;
        padding-bottom: 10px;

        a {
          display: inline-block;
          padding-left: 15px;
        }
      }
    }

    h3 {
      text-transform: uppercase;
      color: $dark;
    }
  }
}

// --------------------------------------------------
// Footer Copyrights
// --------------------------------------------------

.footer-copyrights {
  padding: 15px 0;
  background: $colorAccent1;

  @media only screen and (max-width: 899px) {
    flex-wrap: wrap;
    margin-top: 25px;
    border-top: 2px solid rgba(0, 0, 0, .05);
    text-align: center;
  }

  p {
    max-width: inherit;
    color: #fff;
    font-weight: 600;
    font-size: 14px;

    a, strong {
      color: $colorAccent3;
    }

    @media only screen and (max-width: 899px) {
      text-align: center;
    }
  }

  .right {
    text-align: right;
  }
}


// --------------------------------------------------
// Block - Accordion
// --------------------------------------------------

.block-accordion {

}

.block-accordion > ul {
  list-style: none;
  padding: 0;
}

.block-accordion-label {
  position: relative;
  margin: 0;
  padding: 16px 60px 14px 24px;

  border-top: 1px $lighter solid;
  border-right: 1px $lighter solid;
  border-left: 1px $lighter solid;
  background: #fff;
  font-weight: 400;

  @extend .small-title;
  color: #4A5560;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  .icon {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
}

.is-danger .block-accordion-label {
  background-color: $colorDanger;
  color: #fff;

  .icon, i, p .icon, p i {
    color: #fff;
  }
}

.block-accordion-row {

  &.is-active {
    .i-plus:after {
      display: none;
    }

    .block-accordion-label {
      // background: $lighter;
      // color: $colorAccent1;
    }
  }

  &:first-child .block-accordion-label {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child .block-accordion-label,
  &:last-child .block-accordion-content {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 2px $lighter solid;
  }

  &:last-child .block-accordion-content {
    border-top: none;
  }
}

.block-accordion-content {
  display: none;
  padding: 24px 24px 22px;
  border: 1px $lighter solid;
  border-bottom: none;
  // background: #fcfcfd;

  img {
    max-width: 100%;
  }

  .button,
  .button:visited {
    color: #fff;
  }

  .block-text-table {
    width: 100%;

    img {
      display: inline-block;
      width: 100%;
      height: auto;
    }
  }
}


.uploader-zone {
  .post-content.upload-zone {
    position: relative;
    padding: 75px;
    margin: -25px 0 0 0;
    border: 2px dashed $lighter;
    border-radius: 4px;
    background: url('/img/site/drag-drop-thumbnail.png');
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: center;

    transition: all 0.35s ease;

    &:hover {
      opacity: .60;
    }
  }

  .files-list {
    display: flex;
    flex-wrap: wrap;
  }

  .file-row {
    border-radius: 50px;
    margin: 0 0 10px 0;
    padding: 10px 20px;
    border: 1px solid #eaecef;
    color: $dark;
    font-size: 12px;
    line-height: 1;
    width: auto;
    min-width: calc(100% / 3.8);
    margin-right: 10px;
    box-shadow: none;
    text-align: center;

    a {
      color: $dark;

      &:hover {
        text-decoration: underline;
      }
    }

    i {
      font-size: 14px;
    }
  }

  [data-file-remove] {
    color: #e74c3c;

    i {
      color: #e74c3c;
    }
  }

  [type="submit"],
  .upload-helper {
    padding: 0;
    margin: 0.5rem 0 0 0;
    cursor: pointer;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 700;
    color: #68788b;
    background: #f6f8f9;

    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: inherit;
    border: 1px solid $lighter;
    border-radius: 4px;
    box-shadow: none;
    display: block;
    line-height: 1.5;
    position: relative;
    vertical-align: top;

    &:hover {
      border-color: #bec5ce;
    }
  }

  [type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    outline: none;
    cursor: pointer;
    opacity: 0;
  }

  .upload-helper {
    position: absolute;
    top: 15px;
    left: 20px;
    opacity: 0;
    z-index: 1;

    .files-count {
      display: inline-block;
      background: rgba($colorAccent1, 0.65);
      border-radius: 50%;
      padding: 3px;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 14px;
      margin-right: 5px;
      color: #fff;
    }
  }
}

// --------------------------------------------------
// Dashborad Tabs navigation
// --------------------------------------------------
.dashboard {

  .dashboard-tabs-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    border-bottom: 2px solid $lighter;
    margin-bottom: 45px;
    align-items: stretch;
    display: flex;
    justify-content: center;
    white-space: nowrap;

    @media (max-width: 1222px) {
      justify-content: flex-start;
    }
  }

  .dashboard-tabs {
    align-items: stretch;
    display: flex;
    width: 100%;
    justify-content: center;
    white-space: nowrap;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 45px;
    margin-bottom: -2px;

    @media (max-width: 1222px) {
      justify-content: flex-start;
    }

    ul {
      align-items: center;
      border-bottom-color: #dbdbdb;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      align-items: stretch;
      justify-content: center;
      border-bottom: none;
      list-style: none;
      margin: 0;
      padding: 0;

      @media (max-width: 1222px) {
        justify-content: flex-start;
      }

      li {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
      }

      a {
        border-style: solid;
        margin-bottom: 0;
        position: relative;
        border-style: solid;
        border-color: $lighter;
        border-width: 1px;
        margin-bottom: 0;
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 16px 14px 14px;
        vertical-align: top;
        cursor: pointer;
        text-decoration: none;
        margin-right: -2px;
        background: #fff;
        font-weight: 500;
        font-size: 13px;
        color: $dark;
        text-transform: uppercase;
        line-height: 1;

        &:hover {
          opacity: .75;
        }
      }

      a.is-danger,
      li.is-active a.is-danger {
        background-color: $colorDanger;
        border-color: $colorDanger;

        i.icon, span {
          color: #fff;
        }
      }

      li:first-child a {
        border-radius: 4px 0 0 0;
      }

      li:last-child a {
        border-radius: 0 4px 0 0;
      }

      li.is-active {
        a {
          background-color: $lighter;
          // border-color: $colorAccent1;
          // border-bottom: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          // padding-bottom: 16px;
          // color: $colorAccent1;
          z-index: 1;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .icon {
      margin-right: 5px;
    }

    .icon-circle {
      position: relative;
      top: -2px;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: $colorAccent3;
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      text-transform: none;
      width: 18px;
      height: 18px;
      margin-bottom: -4px;

      i {
        font-style: normal;
        color: #fff;
      }
    }
  }
}
