
/*
 *      Colors
 *      ------
 */

$white      : #ffffff;
$primary-light      : #ffffff;
$secondary-light  : #ffffff;
$third-light        : #ffffff;
$primary-dark       : rgba(#00152e, 0.50);
$secondary-dark     : #4c535b;
$primary-color      : $colorAccent3;
$secondary-color    : $colorAccent3;
$secondary-color-dark    : $colorAccent3;

$error-color        : #e64900;
$success-color      : #3e8b3d;


/*
 *      Fonts
 *      -----
 */

$primary-font       : 'Roboto Slab', 'Open Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontTitle       : 'Roboto Slab', 'Open Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$secondary-font       : 'Roboto Slab', 'Open Sans', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$backup-font       : 'Roboto Slab', 'Open Sans', 'Karla', 'Helvetica Neue', Helvetica, Arial, sans-serif;




/*
 *      Breakpoints
 *      -----------
 */

$tiny-breakpoint    : 640px;
$small-breakpoint   : 980px;
$medium-breakpoint  : 1060px;
$large-breakpoint   : 1320px;
$huge-breakpoint    : 1430px;


/*
 *      Site Continer Size
 *      ------------------
 */

$max-site-width     : 1120px;
$min-site-width     : 900px;
$tiny-site-width    : 480px;




/*
 *      Input
 *      -----
 */

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #bebebe;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #bebebe;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #bebebe;
}
:-moz-placeholder { /* Firefox 18- */
  color: #bebebe;
}

form * {
    outline: none;
}

.input,
.select {
    width: 100%;
    padding: 12px 15px;
    margin-bottom: 15px;
    border-radius: 2px;
    border: 2px solid $third-light;
    background-color: $third-light;
    color: $primary-dark;
    font-size: .875em;
    font-family: $primary-font;
    font-weight: 400;

    &.tiny {

        width: 20%;
        min-width: 60px;
        max-width: 80px;
    }

    &.small {

        width: 40%;
        min-width: 120px;
        max-width: 120px;
    }

    &.medium {

        width: 50%;
        min-width: 220px;
        max-width: 320px;
    }

    &.big {
        width: 100%;
        min-width: 320px;
        max-width: 600px;

        @media (max-width: 599px) {
            min-width: 0;
        }
    }

    &.error,
    .error & {

        border: 2px solid $error-color;
    }

    &:focus {

        border: 2px solid $secondary-color;
        color: $secondary-color;
    }

    &[data-company-input] {

        margin-top: 10px;
    }
}

.select {
  height: 45px;
  line-height: 45px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1;
  margin: auto;
  padding: 0;
  margin-bottom: 15px;

  font-weight: 300;
  font-size: 14px;

  &:first-child {
    margin-top: 5px;
  }

  &:last-child {
    margin-bottom: 35px;
  }
}

.checkbox,
.radio {
  margin-right: 10px;
}

/*
 *      Input
 *      -----
 */
.textarea {
    width: 100%;
    height: 200px;
    padding: 12px 15px;
    resize: none;
    border-radius: 2px;
    border: 2px solid $third-light;
    background-color: $third-light;
    color: $primary-dark;
    font-size: .875em;
    font-family: $primary-font;
    font-weight: 400;

    &.tiny {
        width: 20%;
        min-width: 60px;
        max-width: 80px;
    }

    &.small {
        width: 40%;
        min-width: 120px;
        max-width: 120px;
    }

    &.medium {
        width: 50%;
        min-width: 220px;
        max-width: 320px;
    }

    &.big {
        width: 100%;
        min-width: 320px;
        max-width: 100%;

        @media (max-width: 599px) {
            min-width: 0;
        }
    }

    &.error {
        border: 2px solid $error-color;
    }

    &:focus {
        border: 2px solid $secondary-color;
        color: $secondary-color;
    }
}


form [disabled] {
    color: #777;
}


/*
 *      Label
 *      -----
 */

.label {
    display: inline-block;
    padding-bottom: 5px;
    color: #00152e;
    font-size: 14px;
    font-family: $primary-font;
    font-weight: 400;
    margin-bottom: 0;
}




/*
 *      Input Select
 *      ------------
 */
.input-select {
    position: relative;
    background: $third-light;

    .icon {
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translate3d(0, -50%, 0);
        z-index: 1;
    }
}
select {
    position: relative;
    width: 100%;
    padding: 12px 15px;
    border-radius: 2px;
    border: 2px solid $third-light;
    background-color: transparent;
    color: $primary-dark;
    font-size: .875em;
    font-family: $primary-font;
    font-weight: 400;
    z-index: 2;
}
// .input-select {

//     position: relative;
//     width: 100%;
//     padding: 12px 15px;
//     border-radius: 2px;
//     border: 2px solid $third-light;
//     background-color: $third-light;
//     padding-right: 22px;
//     cursor: pointer;

//     select {
//         width: 100%;
//         border: none;
//         background-color: transparent;
//         color: $primary-dark;
//         font-size: .875em;
//         font-family: $primary-font;
//         font-weight: 400;
//         cursor: pointer;

//         &.parsley-success {

//             background-color: red;

//             &:before {

//                 content: '';
//                 position: absolute;
//                 background-color: #DFF0D8;
//                 top: 0;
//                 left: 0;
//                 bottom: 0;
//                 right: 0;
//             }
//         }
//     }

//     .icon {

//         position: absolute;
//         top: 50%;
//         right: 20px;
//         height: 6px;
//         transform: translateY(-50%);
//     }

//     &.tiny {

//         width: 20%;
//         min-width: 60px;
//         max-width: 80px;
//     }

//     &.small {

//         width: 40%;
//         min-width: 120px;
//         max-width: 120px;
//     }

//     &.medium {

//         width: 50%;
//         min-width: 220px;
//         max-width: 320px;
//     }

//     &.big {

//         width: 100%;
//         min-width: 320px;
//         max-width: 600px;
//     }

//     &.error {

//         border: 2px solid $error-color;
//     }
// }

.input-select-other {

    margin-top: 12px;
}


/*
 *      Select
 *      ------
 */

.select {

    position: relative;
    padding-right: 22px;
    cursor: pointer;

    select {

        width: auto;
        padding: 10px 15px 10px 0;
        border: none;
        background-color: transparent;
        color: $secondary-color;
        font-size: .925em;
        font-family: $primary-font;
        font-weight: 400;
        text-transform: uppercase;
        cursor: pointer;
    }

    .icon {

        position: absolute;
        top: 50%;
        right: 20px;
        height: 6px;
        transform: translateY(-50%);

        z-index: -1;
    }

    &.resizable {

        .input-select {

            width: 45px;
        }

        .fake-select {

            display: none;
            visibility: hidden;
            font-size: .925em;
            font-family: $primary-font;
            font-weight: 400;
        }
    }
}




/*
 *      Checkbox
 *      --------
 */

.checkbox {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    input {

        display: none;
    }

    label {

        display: inline-block;
        cursor: pointer;
        position: relative;
        padding-left: 40px;
    }

    label:before {

        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 22px;
        height: 22px;
        margin-right: 10px;
        border-radius: 2px;
        border: 1px solid $primary-color;
        background-color: $primary-light;
        transform: translateY(-50%);
    }

    input:checked + label:after {

        content: '';
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 50%;
        width: 24px;
        height: 24px;
        border-radius: 2px;
        background-color: $primary-color;
        color: $primary-light;
        font-size: 1em;
        line-height: 0.5em;
        transform: translateY(-50%);

    }

    &:hover {

        label:before {

            background-color: rgba($primary-color, .1);
        }
    }

    &.big {

        label {

            padding-left: 50px;
        }

        label:before {

            width: 30px;
            height: 30px;
        }

        input:checked + label:after {

            width: 32px;
            height: 32px;
            font-size: 1.2em;
        }
    }
}




/*
 *      Radio
 *      -----
 */

.radio {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    input {

        display: none;
    }

    label {

        display: inline-block;
        cursor: pointer;
        position: relative;
        padding-left: 40px;
    }

    label:before {

        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 22px;
        height: 22px;
        margin-right: 10px;
        border-radius: 50%;
        border: 1px solid $primary-color;
        background-color: $primary-light;
        transform: translateY(-50%);
    }

    input:checked + label:after {

        content: '\2713';
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 50%;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $primary-color;
        color: $primary-light;
        font-size: 1em;
        line-height: 0.5em;
        transform: translateY(-50%);

    }

    &:hover {

        label {

            color: $primary-color;
        }

        label:before {

            background-color: rgba($primary-color, .1);
        }
    }
}




/*
 *      Input Label
 *      -----------
 */

.input-label {

    display: flex;
    flex-direction: column;
    text-align: left;

    &.no-label {

        @media screen and (min-width: $small-breakpoint) {

            & {

                padding-top: 27px;
            }
        }
    }
}




/*
 *      Input Group 33
 *      --------------
 */

.input-group-33 {

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $min-site-width;

    @media screen and (min-width: $small-breakpoint) {

        & {

            flex-direction: row;
        }
    }

    .input-label {

        width: 100%;
        margin-top: 30px;

        &:first-child {

            margin-top: 0;
        }

        @media screen and (min-width: $small-breakpoint) {

            & {

                width: calc(33.333% - 20px);
                margin-top: 0;
                margin-left: 30px;
            }

            &:first-child {

                margin-left: 0;
            }
        }
    }
}




/*
 *      Input Group 50
 *      --------------
 */

.input-group-50 {

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $min-site-width;

    @media screen and (min-width: $small-breakpoint) {

        & {

            flex-direction: row;
        }
    }

    .input-label {

        width: 100%;
        margin-top: 30px;

        &:first-child {

            margin-top: 0;
        }

        @media screen and (min-width: $small-breakpoint) {

            & {

                width: calc(50% - 15px);
                margin-top: 0;
                margin-left: 30px;
            }

            &:first-child {

                margin-left: 0;
            }
        }

        .input,
        .input-select {

            max-width: 480px;
        }
    }

    &.offset-25 {
        @media screen and (min-width: $small-breakpoint) {

            max-width: 100%;

            .input-label {

                margin-left: 25%;
            }
        }
    }
}




/*
 *      Input Group 70
 *      --------------
 */

.input-group-70 {

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $min-site-width;

    @media screen and (min-width: $small-breakpoint) {

        & {

            flex-direction: row;
        }
    }

    .input-label {

        width: 100%;
        margin-top: 30px;

        &:first-child {

            margin-top: 0;
        }

        @media screen and (min-width: $small-breakpoint) {

            & {

                width: calc(30% - 15px);
                margin-top: 0;
                margin-left: 30px;
            }

            &:first-child {

                width: calc(70% - 15px);
                margin-left: 0;
            }
        }
    }
}




/*
 *      Input Group 100
 *      ---------------
 */

.input-group-100 {

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $min-site-width;

    @media screen and (min-width: $small-breakpoint) {

        & {

            flex-direction: row;
        }
    }

    .input-label {

        width: 100%;

        @media screen and (min-width: $small-breakpoint) {

            & {

                width: 70%;
            }

            &.big {
                width: 100%;
            }
        }
    }
}



/*
 *      Input Button Group
 *      ------------------
 */

.input-group-button {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 360px;

    input {

        width: 100%;
        padding: 10px 15px;
        border-radius: 2px 0 0 2px;
        border: none;
        background-color: $primary-light;
        color: $primary-dark;
        font-size: .875em;
        font-family: $primary-font;
        font-weight: 400;
    }

    button {

        padding: 11px 15px;
        border-radius: 0 2px 2px 0;
        border: none;
        background-color: $primary-dark;
        color: $primary-light;
        font-size: .925em;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        transition: background-color .2s ease-in-out;

        &:hover {

            background-color: $primary-color;
        }

        &.primary-color {

            background-color: $primary-color;

            &:hover {

                background-color: $primary-dark;
            }
        }

        &.secondary-color {

            background-color: $secondary-color;

            &:hover {

                background-color: $primary-dark;
            }
        }
    }
}




/*
 *      Input Group Radio/Checkbox
 *      -----------------
 */

.input-group-radio {

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: $min-site-width;

    @media screen and (min-width: $small-breakpoint) {

        & {

            flex-direction: row;
        }
    }

    .radio,
    .checkbox {

        margin-top: 15px;

        &:first-child {

            margin-top: 0;
        }

        @media screen and (min-width: $small-breakpoint) {

            & {

                margin-top: 0;
                margin-left: 60px;
            }

            &:first-child {

                margin-left: 0;
            }
        }
    }
}




/*
 *      Input Group Checkbox
 *      --------------------
 */

.input-group-checkbox {


    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: $min-site-width;

    .checkbox {

        margin-top: 20px;

        &:first-child {

            margin-top: 0;
        }
    }
}




/*
 *      Input Search Group
 *      ------------------
 */

.input-search-group {

    position: relative;
    display: block;
    width: 100%;
    max-width: 320px;
    padding: 20px 0 22px 10%;
    border-bottom: 1px solid $secondary-light;
    box-sizing: border-box;

    input {

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 15px 10% 5px 0;
        border: 0;
        box-sizing: border-box;
        background-color: transparent;
        color: $primary-color;
        font-size: 1em;
        font-weight: 400;
    }

    .submit {

        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding: 15px 0 5px 0;
        border: 0;
        box-sizing: border-box;
        background-color: transparent;
        color: $secondary-light;
        font-size: 1em;
        font-weight: 400;
        text-transform: uppercase;
        transition: right .1s ease-in-out;
        cursor: pointer;

        &:hover {

            color: $primary-color;
        }
    }

    &.big {

        width: 80%;
        max-width: $max-site-width;
        margin: 0 auto;

        @media screen and (min-width: $tiny-breakpoint) {

            & {

                padding: 20px 0 30px;
            }
        }

        @media screen and (min-width: $small-breakpoint) {

            & {

                padding: 25px 0 35px;
            }
        }

        @media screen and (min-width: $medium-breakpoint) {

            & {

                padding: 30px 0 40px;
            }
        }

        input {

            font-size: 1em;
            text-transform: uppercase;

            @media screen and (min-width: $tiny-breakpoint) {

                & {

                    font-size: 1.4em;
                }
            }

            @media screen and (min-width: $small-breakpoint) {

                & {

                    font-size: 2em;
                }
            }

            @media screen and (min-width: $medium-breakpoint) {

                & {

                    font-size: 2.6em;
                }
            }
        }

        .submit {

            font-size: 1em;

            @media screen and (min-width: $tiny-breakpoint) {

                & {

                    font-size: 1.4em;
                }
            }

            @media screen and (min-width: $small-breakpoint) {

                & {

                    font-size: 2em;
                }
            }

            @media screen and (min-width: $medium-breakpoint) {

                & {

                    font-size: 2.6em;
                }
            }

            &:hover {

                right: 15px;
                color: $primary-color;
            }
        }
    }

    &.icon {

        width: calc(100% - 40px);

        .submit {

            top: 70%;
            right: -30px;

            .icon {

                height: 28px;
                width: auto;
            }

            &:hover {

                .icon {

                    fill: $primary-color;

                    svg {

                        fill: $primary-color;
                    }
                }
            }
        }
    }
}




/*
 *      Dropdown Group
 *      --------------
 */

.dropdown-group {

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 360px;

    select {

        width: 100%;
        padding: 10px 15px;
        border-radius: 2px 0 0 2px;
        border: none;
        background-color: $primary-light;
        color: $primary-dark;
        font-size: .875em;
        font-family: $primary-font;
        font-weight: 400;
    }

    .icon {

        position: absolute;
        right: 20px;
        cursor: pointer;
    }
}




/*
 *      Error Message
 *      --------------
 */

.error-message {

    padding-top: 15px;
    color: $error-color;
    font-size: .875em;
    font-weight: 400;
    line-height: 1.4em;
}


#ticket_error_msg {
    .parsley-errors-list {
        display: none;

        &:first-child {
            display: block;
        }
    }
}

/*
 *      Form submit button loading animation
 *      --------------
 */

.loading-animation {
    position: relative;
    color: $white;
    opacity: .8;

    &:after {
        content: '';
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border-top: 2px solid black;
        border-left: 2px solid black;
        border-right: 2px solid transparent;
        animation: spinner .5s linear infinite;
        position: absolute;
        top: 11px;
        right: 14px;
        margin-left: 10px;
    }
}

[data-form-button][type="submit"] {
    &[disabled] {
        @extend .loading-animation;

        &:after {
            [data-form="contact"] & {
                top: 8px;
                width: 16px;
                height: 16px;
            }
        }

        &:hover {
            background-color: $secondary-color;
        }
    }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}
