W.main-form {

  .form-row {
    position: relative;
    width: 100%;
  }

  .form-controls {
    width: 100%;
  }

  .form-label {
    @extend .regular-text;

    display: block;
    width: 100%;
    font-weight: 600;
    letter-spacing: .025em;
  }

  .form-input {
    display: block;
    width: 100%;
    // max-width: 635px;
    // min-width: 50px;
    min-height: 52px;
    padding: 0 15px;
    margin: 10px 0 20px 0;
    border: 2px solid #d0d0d0;
    border-radius: 3px;
    background-color: #fff;
    font-size: 17px;
    line-height: 20px;
    vertical-align: middle;
  }

  .form-submit {
    @extend .regular-text;

    width: 100%;
    max-width: 635px;
    min-width: 50px;
    min-height: 52px;
    padding: 0 15px;
    margin: 10px 0 20px 0;
    border: 2px solid $colorAccent3;
    border-radius: 3px;
    background-color: $colorAccent3;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;

    transition: all 0.35s ease;

    cursor: pointer;

    &:hover {
      color: $colorAccent3;
      background: #fff;
    }
  }
}

.form-footer {
  text-align: center;
}

.auth-form-notifications {
  width: 100%;
  height: auto;
  margin: 5px 0 15px 0;

  p {
    margin: 0 0 5px 0;
    padding: 0;
  }

  span {
    display: inline-block;
    margin-top: 10px;
  }

  .error {
    padding: 15px;
    border-radius: 4px;
    border-left: 4px solid #ff4f81;
    background-color: rgba(255,79,129,0.1);
    color: #852943;
    line-height: 1;
  }

  .success {
    padding: 15px;
    border-radius: 4px;
    border-left: 4px solid #2dde98;
    background-color: rgba(45,222,152,0.1);
    color: #17734f;
    line-height: 1;
  }

  &.is-label-helper {
    .success {
      border-color: $colorAccent1;
      background: -moz-linear-gradient(left, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0) 50%);
      background: -webkit-linear-gradient(left, rgba(0,0,0,0.05) 0%,rgba(0,0,0,0) 50%);
      background: linear-gradient(to right, rgba(0,0,0,0.05) 0%,rgba(0,0,0,0) 50%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
      border-bottom-left-radius: 0;
      line-height: 1.5;
    }
  }
}


// Print Mode

.wedding-form-component {
  .site-btn {
    margin-bottom: 15px;
  }
}



@media print {
  body,
  .main,
  #site-container {
    display: block !important;
    width: 100%;

    .form-alert.regular-text,
    .next-button,
    .prev-button,
    .field-instructions.regular-text,
    .section-dashboard,
    .user-hero,
    #footer {
      display: none !important;
    }

    .wedding-form-component {
      position: relative;
      display: block !important;
      width: 100% !important;
      margin: 0 !important;
      transform: none !important;
      left: auto !important;
      max-width: 100%  !important;

      * { font-size: 12px !important; }

      .big-title {
        font-size: 16px !important;
      }

      .medium-title {
        padding: 0 0 5px 0;
        margin: 0 0 5px 0;
        color: #000;
        background: none;
        font-weight: 800;
        font-size: 16px !important;
      }

      ol {
        display: none !important;
      }

      .wedding-form-section {
        display: block !important;
        width: 100% !important;
        top: 0 !important;
        border-bottom: 1px solid #ebebeb;
      }

      .wedding-form {
        padding: 0 0 0 0;
        display: flex;
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;

        .field {
          width: 100%;
        }
      }

      .site-btn {
        display: none !important;
      }

      .input, .select, textarea {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
        padding: 0;
        color: #0D416B;
        background: none;
        border: none;
        text-indent: 0;
        overflow: visible;
        white-space: normal;
        line-break: auto;
        word-wrap: break-word;
        word-break: break-word;
        font-size: 12px;
        field-sizing: content;
      }
    }
  }
}

  body.test-print {
    &,
    .main,
    #site-container {
      display: block !important;
      width: 100%;

      .form-alert.regular-text,
      .next-button,
      .prev-button,
      .field-instructions.regular-text,
      .section-dashboard,
      .user-hero,
      #footer {
        display: none !important;
      }

      .wedding-form-component {
        position: relative;
        display: block !important;
        width: 100% !important;
        margin: 0 !important;
        transform: none !important;
        left: auto !important;
        max-width: 100%  !important;

        * { font-size: 12px !important; }

        .big-title {
          font-size: 16px !important;
        }

        .medium-title {
          padding: 0 0 5px 0;
          margin: 0 0 5px 0;
          color: #000;
          background: none;
          font-weight: 800;
          font-size: 16px !important;
        }

        ol {
          display: none !important;
        }

        .wedding-form-section {
          display: block !important;
          width: 100% !important;
          top: 0 !important;
          border-bottom: 1px solid #ebebeb;
        }

        .wedding-form {
          padding: 0 0 0 0;
          display: flex;
          width: 100%;
          max-width: 100%;
          flex-wrap: wrap;
          justify-content: space-between;
          flex-direction: column;

          .field {
             width: 100%;
          }
        }

        .site-btn {
          display: none !important;
        }

        .input, .select, textarea {
          display: block;
          width: 100%;
          max-width: 100%;
          height: auto;
          padding: 0;
          color: #0D416B;
          background: none;
          border: none;
          text-indent: 0;
          overflow: visible;
          white-space: normal;
          line-break: auto;
          word-wrap: break-word;
          word-break: break-word;
          font-size: 12px;
          field-sizing: content;
        }
      }
    }
  }

