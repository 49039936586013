.site-wrapper {
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
}

.grid-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;

  .grid-col {
    width: 100%;
  }
}
