@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?18037651');
  src: url('../font/fontello.eot?18037651#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?18037651') format('woff2'),
       url('../font/fontello.woff?18037651') format('woff'),
       url('../font/fontello.ttf?18037651') format('truetype'),
       url('../font/fontello.svg?18037651#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?18037651#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-search:before { content: '\e800'; } /* '' */
.icon-twitter:before { content: '\e801'; } /* '' */
.icon-facebook:before { content: '\e802'; } /* '' */
.icon-mail-alt:before { content: '\e803'; } /* '' */
.icon-location:before { content: '\e804'; } /* '' */
.icon-left-open:before { content: '\e805'; } /* '' */
.icon-right-open:before { content: '\e806'; } /* '' */
.icon-youtube-play:before { content: '\e807'; } /* '' */
.icon-book-open:before { content: '\e808'; } /* '' */
.icon-attention-alt:before { content: '\e809'; } /* '' */
.icon-share:before { content: '\e80a'; } /* '' */
.icon-minus-circled:before { content: '\e80b'; } /* '' */
.icon-help-circled:before { content: '\e80c'; } /* '' */
.icon-info-circled:before { content: '\e80d'; } /* '' */
.icon-calendar:before { content: '\e80e'; } /* '' */
.icon-down-dir:before { content: '\e80f'; } /* '' */
.icon-star:before { content: '\e810'; } /* '' */
.icon-star-half-alt:before { content: '\e811'; } /* '' */
.icon-star-empty:before { content: '\e812'; } /* '' */
.icon-linkedin:before { content: '\e813'; } /* '' */
.icon-pinterest:before { content: '\e814'; } /* '' */
.icon-github-circled:before { content: '\e815'; } /* '' */
.icon-flash:before { content: '\e816'; } /* '' */
.icon-link:before { content: '\e817'; } /* '' */
.icon-check:before { content: '\e818'; } /* '' */
.icon-plus:before { content: '\e819'; } /* '' */
.icon-minus:before { content: '\e81a'; } /* '' */
.icon-view:before { content: '\e81b'; } /* '' */
.icon-download-alt:before { content: '\e81c'; } /* '' */
.icon-search-1:before { content: '\e81d'; } /* '' */
.icon-phone-call:before { content: '\e81e'; } /* '' */
.icon-mail:before { content: '\e81f'; } /* '' */
.icon-fax:before { content: '\e820'; } /* '' */
.icon-download:before { content: '\e821'; } /* '' */
.icon-globe:before { content: '\e822'; } /* '' */
.icon-download-1:before { content: '\e823'; } /* '' */
.icon-list:before { content: '\e824'; } /* '' */
.icon-calendar-1:before { content: '\e825'; } /* '' */
.icon-ok-circled:before { content: '\e826'; } /* '' */
.icon-info:before { content: '\e827'; } /* '' */
.icon-play-circle2:before { content: '\e828'; } /* '' */
.icon-play-circled2:before { content: '\e829'; } /* '' */
.icon-play-circled:before { content: '\e82a'; } /* '' */
.icon-trash:before { content: '\e82b'; } /* '' */
.icon-glass:before { content: '\e82c'; } /* '' */
.icon-facebook-circled:before { content: '\f051'; } /* '' */
.icon-twitter-circled:before { content: '\f057'; } /* '' */
.icon-gplus-circled:before { content: '\f059'; } /* '' */
.icon-linkedin-circled:before { content: '\f05b'; } /* '' */
.icon-instagram-circled:before { content: '\f05e'; } /* '' */
.icon-info-circled-alt:before { content: '\f086'; } /* '' */
.icon-rss:before { content: '\f09e'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-angle-double-down:before { content: '\f103'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-dollar:before { content: '\f155'; } /* '' */
.icon-down:before { content: '\f175'; } /* '' */
.icon-dot-circled:before { content: '\f192'; } /* '' */