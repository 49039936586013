// Login Component
.login-component {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: no-wrap;
  width: 100%;
  min-height: 100vh;

  @media (max-width: 999px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .login-aside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 33.33333333%;
    padding: 40px 75px;

    @media (max-width: 1429px) {
      padding: 40px 40px;
    }

    @media (max-width: 1129px) {
      padding: 40px 20px;
    }

    @media (max-width: 999px) {
      width: 100%;
    }

    .login-big-title {
      @extend .big-title;

      @media (max-width: 767px) {
        font-size: 31px;
      }
    }

    .login-regular-text {
      @extend .regular-text;
    }

    .login-aside-header {
      text-align: center;
    }

    .login-logo {
      img {
        display: block;
        width: 100%;
        max-width: 135px;
        height: auto;
        margin: 0 auto;

        @media (max-width: 767px) {
          max-width: 95px;
        }
      }
    }

    .login-aside-footer {
      text-align: center;

      .login-regular-text {
        color: $grey;
      }
    }
  }

  .login-visual {
    position: relative;
    width: calc(100% - 33.33333333%);
    background-image: url('https://static.wixstatic.com/media/b68936_a71352f765a04745823f1da0098e6f4a~mv2_d_4248_2832_s_4_2.jpg/v1/fill/w_1920,h_1280,al_c,q_85,usm_0.66_1.00_0.01/b68936_a71352f765a04745823f1da0098e6f4a~mv2_d_4248_2832_s_4_2.webp');
    background-position: center;
    background-size: cover;

    @media (max-width: 999px) {
      width: 100%;
      height: 25vw;
      min-height: 200px;
    }

    .login-visual-header {
      padding: 75px;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.35+1,0+100 */
      background: -moz-linear-gradient(left, rgba(255,255,255,0.35) 0%, rgba(255,255,255,0.35) 1%, rgba(255,255,255,0) 50%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(255,255,255,0.35) 0%,rgba(255,255,255,0.35) 1%,rgba(255,255,255,0) 50%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(255,255,255,0.35) 0%,rgba(255,255,255,0.35) 1%,rgba(255,255,255,0) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

      @media (max-width: 1429px) {
        padding: 40px 40px;
      }

      @media (max-width: 1129px) {
        padding: 20px;
      }

      @media (max-width: 767px) {
        text-align: center;

        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.25+1,0+100 */
        background: -moz-linear-gradient(top, rgba(255,255,255,0.55) 0%, rgba(255,255,255,0.55) 1%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0.55) 0%,rgba(255,255,255,0.55) 1%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0.55) 0%,rgba(255,255,255,0.55) 1%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
      }

      .medium-title {
        margin: 0;
        padding: 0;
        color: #333;
      }

      .visual-title {
        font-weight: 600;
      }

      .visual-subtitle {
        font-size: 18px;
        line-height: 1.2;
        font-weight: 400;
      }
    }

    .visual-socials-links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      bottom: 75px;
      right: 75px;
      margin: 0;
      padding: 0;
      list-style-type: none;

      @media (max-width: 1429px) {
        bottom: 40px;
        right: 40px;
      }

      @media (max-width: 1129px) {
        bottom: 20px;
        right: 20px;
      }

      @media (max-width: 767px) {
        right: 50%;
        transform: translate3d(50%, 0, 0);
      }

      li {
        margin: 0;
        padding: 0;
        color: #fff;

        a {
          font-size: 35px;
          transition: all 0.35s ease;

          &:hover {
            color: $colorAccent3;
          }
        }
      }
    }
  }
}
