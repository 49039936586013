// --------------------------------------------------
// Block
// --------------------------------------------------

.site-block {

}


// --------------------------------------------------
// Section Hero
// --------------------------------------------------

.block-section-hero {
  position: relative;
  width: 100%;
  height: 27vw;
  padding: 100px;
  background: #434A98;
  background-image: url('/img/site/ph1-hero.jpg');
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #444B99;
    z-index: 1;
    opacity: .90;
  }

  @media (max-width: 1099px) {
    padding: 20px;
  }

  @media (max-width: 699px) {
    height: 125px;
  }

  .separator-content-wrapper {
    height: 100%;
  }

  .block-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    margin: 0;
    font-family: $fontTitle;
    color: #fff;
    text-align: center;
    letter-spacing: 0.055em;
    z-index: 2;

    @media (max-width: 767px) {
      font-size: 14px;
      min-width: 200px;
    }
  }
}

// --------------------------------------------------
// Block Introduction
// --------------------------------------------------

.block-introduction {
  display: flex;
  padding: 0 0 0 0;
  border-bottom: 1px solid $lighter;
  background: url('/img/site/intro-ph.png');
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;

  .block-content {
    width: 100%;
    padding: 75px;
    background: rgba(255, 255, 255, 0.80);

    @media only screen and (max-width: 767px) {
      padding: 20px;
    }
  }

  .big-title {
    margin-bottom: 15px;
  }

  .column {
    padding-right: 35px;

    &.last {
      padding-right: 0;
    }
  }

  .regular-text {

    strong {
      font-family: $fontTitle;
      font-size: 18px;
      color: $colorAccent3;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    @media only screen and (max-width: 767px) {
      font-size: 14px;

      ul,
      ol {
        padding: 0 15px;
        margin: 0;
      }
    }
  }
}

// --------------------------------------------------
// Split text image
// --------------------------------------------------

.block-split-text-image {
  display: flex;
  padding: 0 0 0 0;
  border-bottom: 1px solid $lighter;

  @media only screen and (max-width: 599px) {
    flex-wrap: wrap;
  }

  &.is-left {
    flex-direction: row-reverse;
  }

  .block-content {
    position: relative;
    width: 75%;
    padding: 75px;

    @media only screen and (max-width: 767px) {
      padding: 20px;
    }

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    &.is-collapsed {
      &:after {
        content: '';
        position: absolute;
        top: 65%;
        right: 0;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 65%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 65%,rgba(255,255,255,1) 100%);
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 65%,rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
        z-index: 2;
      }
    }

    .regular-text {
      // margin-bottom: 75px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    h2 {
      @extend .big-title;
      //color: $dark;
      line-height: 1.4;

      @media only screen and (max-width: 767px) {
        font-size: 22px;
        margin: 0;
        padding: 0;
      }
    }

    h3 {
      @extend .big-title;
      color: $dark;
      font-size: 26px;
      line-height: 1.4;
    }

    h4 {
      @extend .big-title;
      color: $dark;
      font-size: 20px;
      line-height: 1.4;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    figure {
      padding: 0;
      margin: 0;
    }

    blockquote {
      padding: 0 0 0 25px;
      margin: 25px 0;
      border-left: 4px solid $lighter;
    }

    .read-more {
      display: none;
      position: absolute;
      right: 75px;
      bottom: 25px;
      z-index: 3;
      padding: 5px 0;
      font-size: 14px;
    }
  }

  .block-image {
    width: 25%;

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    img {
      display: block;
    }
  }
}

// --------------------------------------------------
// Block - Image
// --------------------------------------------------

.block-image {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}


// --------------------------------------------------
// Block - Grid
// --------------------------------------------------

.block-grid {
  display: flex;
  padding: 60px 20px 0;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  //padding: 75px 55px;
  background: #fff;
  text-align: center;

  @media only screen and (max-width: 767px) {
    padding: 45px 20px;
  }

  .medium-title {
    display: inline-block;
    margin: 0 auto;
    color: $grey;

    @media only screen and (max-width: 767px) {
      font-size: 18px;
    }

    &:before,
    &:after {
      background: $grey;
    }
  }

  .grid-title {

  }

  .grid-text {
    padding: 45px 75px;

    @media only screen and (max-width: 767px) {
      padding: 45px 20px;
    }
  }

  .grid-items {
    display: flex;
    width: 100%;
    padding: 60px 20px 0;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media only screen and (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .grid-item {
    width: 33%;
    padding: 10px 15px;
    text-align: center;

    @media only screen and (max-width: 767px) {
      width: 50%;
      padding: 10px 10px 0;
    }

    @media only screen and (max-width: 599px) {
      width: 100%;
    }

    img {
      display: inline-block;
      width: 100%;
      height: auto;
      max-width: 125px;
      border-radius: 50%;
    }

    .small-title {

    }

    .regular-text {

    }
  }
}

// --------------------------------------------------
// Posts
// --------------------------------------------------

.posts-list {
  width: 100%;
  padding: 75px 55px;
  background: #fff;
  text-align: center;

  @media only screen and (max-width: 1349px) {
    padding: 75px 45px;
  }

  @media only screen and (max-width: 1099px) {
    padding: 75px 25px;
  }

  @media only screen and (max-width: 767px) {
    padding: 25px 20px;
  }

  .medium-title {
    display: inline-block;
    margin: 0 auto;
    color: $grey;

    &:before,
    &:after {
      background: $grey;
    }

    @media only screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
}



// --------------------------------------------------
// Content - Pagination
// --------------------------------------------------

.content-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 75px;

  @media (max-width: 1099px) {
    padding: 25px 20px;
  }

  a,
  div {
    display: block;
    height: 35px;
    padding: 10px 15px;

    border-top: 1px $light solid;
    border-bottom: 1px $light solid;
    border-left: 1px $light solid;

    background: #fff;
    color: $dark;
    text-decoration:none;

    &.content-pagination-prev,
    &.content-pagination-next {
      span {
        display: block;
        width: 0;
        height: 0;
        border-top: 6.2px solid transparent;
        border-bottom: 6.2px solid transparent;
      }
    }

    &.content-pagination-prev {
      border-radius: 3px 0 0 3px;

      span {
        border-right: 6.2px solid $colorAccent1;

        &.is-disabled {
          border-right-color: $light;
        }
      }
    }

    &.content-pagination-next {
      border-right: 1px $light solid;
      border-radius: 0 3px 3px 0;

      span {
        border-left: 6.2px solid $colorAccent1;

        &.is-disabled {
          border-left-color: $light;
        }
      }
    }

    &:hover {
      color: $colorAccent1;
    }

    &.is-current {
      background: $colorAccent3;
      color: #fff;
      border-color: $colorAccent3;
    }

    &:last-child {
      border-right: 1px $light solid;
    }
  }
}




.article-details {
  padding: 75px;

  @media (max-width: 767px) {
    padding: 35px 20px 0 20px;
  }

  .article-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px 0;
    border-bottom: 4px solid $lighter;
  }

  .block-content {
    padding-top: 0;
  }

  .block-split-text-image {

    .block-content {
      width: 60%;

      &.is-full {
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
      }
    }

    .block-image {
      width: 40%;
    }
  }
}



.side-social-box {
  color: $grey;
  text-align: center;
  padding: 0 20px 20px 20px;

  h3 {
    padding: 20px 0;
    font-weight: 400;
    border-bottom: 1px solid $grey;
  }

  .socials {
    text-align: center;

    a {
      color: $colorAccent1;
      font-size: 25px;
      margin: 0 5px;
      border: none;
    }
  }
}


.member-profile-box {
  padding: 25px 55px;
  text-align: center;
  border-bottom: 2px solid $alternateWhite;

  .member-profile-img {
    display: inline-block;
    width: 85px;
    height: auto;
    border-radius: 50%;
  }

  .member-profile-header {
    font-family: $fontText;
    color: $colorAccent1;
    font-size: 14px;
  }

  .member-profile-text {
    font-family: $fontText;
    color: $grey;
    font-size: 14px;
  }

  .member-profile-link {
    display: inline-block;
    width: 100%;
    font-family: $fontText;
    color: $colorAccent3;
    border: none;
    font-size: 14px;

    strong {
      font-family: $fontText;
    }
  }
}


// --------------------------------------------------
// Block - Media
// --------------------------------------------------

.block-media {
  width: 100%;
}

.block-media embed,
.block-media iframe,
.block-media object {
  border: 0;
}

.block-media-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.block-media-video embed,
.block-media-video iframe,
.block-media-video object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


// --------------------------------------------------
// Popup MSG
// --------------------------------------------------

.popup-msg-wrapper {
  display: none;

  &.is-active {
    display: block;
  }
}

.popup-msg-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 90;
  background: rgba(0, 0, 0, .85);
}

.icon-close {
  position: fixed;
  top: 50px;
  right: 50px;

  width: 25px;
  height: 25px;

  font-size: 35px;
  font-weight: 600;
  color: #fff;

  cursor: pointer;

  z-index: 95;

  @media (max-width: 1099px) {
    left: 50%;
    transform: translate(-50%, 0);
  }

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    fill: #F2F2F2;
    fill-opacity: 1;
    stroke: rgba(237, 21, 102, 1);
  }

  @media (max-width: 699px) {
    top: 6px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 99;
    color: #333;

    svg {
      fill: #333;
    }
  }
}

.popup-msg-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  max-width: 825px;

  background: #fff;
  border-radius: 12px;
  overflow: hidden;

  z-index: 95;

  @media (max-width: 1099px) {
    max-width: 90%;
  }

  @media (max-width: 699px) {
    top: 25px;
    transform: translate(-50%, 0);
    bottom: 25px;

    .popup-msg-content {
      height: 100%;
      overflow-y: scroll;
    }
  }
}

.popup-msg-title {
  @extend .medium-title;
  width: 100%;
  margin-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  color: $colorAccent1;
  text-align: center;
}

.popup-msg-subtitle {
  @extend .small-title;
  margin-top: 0;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  color: $dark;
  text-align: center;
}

.popup-msg-text {
  @extend .regular-text;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  color: $dark;
  text-align: center;
}

.popup-msg-container .block-media {
  margin-top: -15px;
  margin-bottom: -15px;
}

.block-popup-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 20px 10px 20px;
  margin: 30px 0 0 0;
  text-align: center;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: relative;
    top: -25px;
    margin: 0;
    font-size: 12px;

    cursor: pointer;

    input {
      margin-right: 15px;
    }
  }
}

//
.wedding-form-overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99998;

  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  &.is-active {
    display: block;
  }
}

.wedding-form-component {
  display: none;
  position: fixed;
  top: 5%;
  bottom: 5%;
  left: 50%;
  width: 100%;
  max-width: 1024px;
  transform: translate3d(-50%, 0, 0);
  background: #fff;
  z-index: 99999;
  text-align: center;
  overflow: hidden;

  border-radius: 15px;
  -webkit-box-shadow: 0 25px 100px rgba(31, 41, 51, 0.5);
  box-shadow: 0 25px 100px rgba(31, 41, 51, 0.5);

  &.is-active {
    display: block;
  }

  .big-title {
    margin: 0 0 25px 0;
    padding: 15px;
    line-height: 1;
    background: $dark;
    color: #fff;
    font-size: 25px;

    @media (max-width: 699px) {
      font-size: 16px;
    }
  }

  .medium-title {
    margin: 0 0 25px 0;
    padding: 15px;
    line-height: 1;
    background: $dark;
    color: #fff;
    font-size: 18px;
    border-radius: 5px;

    @media (max-width: 699px) {
      font-size: 14px;
    }
  }

  .form-alert {
    font-family: $fontText;
    margin: 0 0 25px 0;
    padding: 0 15px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    text-align: center;
  }

  ol, li {
    margin: 0 auto;
    padding: 0;
    color: $colorAccent3;
    font-family: verdana;
    font-size: 12px;
  }

  ol {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: relative;

    transform: scale(0.90);

    @media (max-width: 699px) {
      flex-wrap: wrap;
    }

    &:after {
      content: '';
      display: block;
      width: auto;
      height: 3px;
      background-color: $colorAccent3;
      position: absolute;
      top: 50%;
      left: 50px;
      right: 50px;
      transform: translate(0, -50%);
      z-index: 1;

      @media (max-width: 699px) {
        left: 20px;
        right: 20px;
      }
    }
  }

  li {
    list-style-type: none;
    position: relative;
    cursor: pointer;
    z-index: 2;

    &:after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #fff;
      border: 2px solid $colorAccent3;
      transition: background-color .2s ease;
    }
  }

  li:hover:after {

  }

  li.content:after {
    background-color: rgba(76, 175, 80, 1);
    border-color: rgba(76, 175, 80, 1);
  }

  li.active:after {
    background-color: $colorAccent3;
  }

  li.done:after {
    background-color: $colorAccent3;
  }

  span {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    transform: translate(-50%, 0);
    display: inline-block;
    text-align: center;
    text-align: center;
    width: 800px / 8;

    @media (max-width: 699px) {
      display: none;
    }
  }

  //

  .wedding-form-section {
    display: none;
    position: relative;
    top: 60px;
    height: 85%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 1px solid rgba(0, 0, 0, .15);
    padding-top: 25px;
    background: rgba(0, 0, 0, 0.035);

    @media (max-width: 699px) {
      top: 55px;
    }

    &.is-active {
      display: block;
    }
  }

  .field[data-show-only-if] {
    margin-top: -25px;
    margin-bottom: 25px;
  }

  .wedding-form {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 0 25px 0;
    text-align: left;

    @media (max-width: 699px) {
      padding: 0 15px 25px 15px;
    }

    &:last-child {
      padding: 0 0 100px 0;

      @media (max-width: 699px) {
        padding: 0 15px 75px 15px;
      }
    }

    &.is-center {
      text-align: center;
    }
  }

  .is-controls {
    display: none;
  }

  .action-indicator {
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    z-index: 9;
  }

  .previous-button,
  .next-button {
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translate3d(0, 0, 0);
    z-index: 9;

    cursor: pointer;

    &.is-disabled {
      opacity: 0.35;
      cursor: default;
    }

    svg {
      width: 35px;
    }

    @media (max-width: 699px) {
      top: 105px;
      right: 35%;

      &.previous-button {
        left: 35%;
        right: auto;
      }

      &.next-button {
        right: 35%;
        left: auto;
      }

      svg {
        width: 20px;
      }
    }
  }

  .next-button {
    right: 25px;
    left: auto;
  }

  .field-instructions {
    margin: 0 0 25px 0;
    padding: 0;
    line-height: 1;
  }
}

svg.animate path {
  animation: dash 0.75s linear both;
  animation-delay: 0.5s;
}

@keyframes dash {
  0% { stroke-dashoffset: 210; }
  75% { stroke-dashoffset: -220; }
  100% { stroke-dashoffset: -205; }
}
