// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: auto;
  font-size: 14px;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: $fontText;
  font-weight: 400;
  font-size: 16px;
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #C2C7D0;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #C2C7D0;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #C2C7D0;
}
:-moz-placeholder { /* Firefox 18- */
  color: #C2C7D0;
}


/* ==========================================================================
 Browser Selection color
 ========================================================================== */
/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: $colorAccent3;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: $colorAccent3;
  color: #fff;
  text-shadow: none;

}
