// --------------------------------------------------
// Header
// --------------------------------------------------

.main-header {
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0 7px 17px 5px rgba(0, 0, 0, 0.10);

  .main-header-pattern {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.has-aside .main-header {
  box-shadow: none;
}

.main-header-content {
  position: relative;
}

.logo {
  padding: 20px 55px;

  @media only screen and (max-width: 1349px) {
    padding: 20px 45px;
  }

  @media only screen and (max-width: 1099px) {
    padding: 20px 25px;
  }

  @media only screen and (max-width: 989px) {
    padding: 10px 25px;
  }

  @media only screen and (max-width: 767px) {
    padding: 10px 20px;
  }

  img {
    width: 325px;

    @media only screen and (max-width: 1349px) {
      width: 225px;
    }

    @media only screen and (max-width: 1099px) {
      position: relative;
      top: 6px;
      width: 185px;
    }

    @media only screen and (max-width: 1099px) {
      top: 0;
    }

    @media only screen and (max-width: 699px) {
      width: 145px;
    }
  }
}

.topbar {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px 55px 20px 55px;

  @media only screen and (max-width: 1349px) {
    padding: 20px 35px 10px 35px;
  }

  @media only screen and (max-width: 1099px) {
    padding: 20px 15px 10px 25px;
  }

  @media only screen and (max-width: 989px) {
    padding: 10px 15px 10px 25px;
  }

  @media only screen and (max-width: 767px) {
    padding: 10px 10px 10px 25px;
  }

  .socials {

    padding-right: 35px;
    padding-left: 35px;
    margin-right: 25px;
    border-right: 2px solid $lighter;
    border-left: 2px solid $lighter;
    color: $grey;
    font-size: 23px;

    @media only screen and (max-width: 1349px) {
      padding-right: 15px;
      padding-left: 15px;
    }

    @media only screen and (max-width: 1099px) {
      border-left: 0;
    }

    @media only screen and (max-width: 989px) {
      display: none;
    }
  }

  .topbar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.topbar-nav {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $colorAccent1;

  a {
    margin-right: 45px;
    color: $light;
    font-size: 18px;
    letter-spacing: -.01em;

    transition: all .35s ease;

    @media only screen and (max-width: 1349px) {
      margin-right: 25px;
      font-size: 16px;
    }

    @media only screen and (max-width: 989px) {
      display: none;
    }

    &:hover {
      color: $colorAccent3;
    }
  }
}

.main-nav {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 55px 20px 0;
  // border-top: 1px solid $lighter;
  color: $colorAccent1;

  z-index: 2;

  @media only screen and (max-width: 1349px) {
    padding: 0 45px 20px 0;
  }

  @media only screen and (max-width: 1099px) {
    padding: 0 25px 20px 0;
  }

  @media only screen and (max-width: 989px) {
    display: none;
  }

  a {
    display: inline-block;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    letter-spacing: -0.010em;
    margin-right: 40px;

    transition: all 0.35s ease;

    @media only screen and (max-width: 1349px) {
      font-size: 16px;
      margin-right: 25px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &.is-active {
      color: $colorAccent3;
    }
  }

  .site-btn {
    margin-right: 0;

    transition: all 0.35s ease;

    &:hover {
      color: #fff;
    }
  }

  .main-nav-burger {
    margin-right: auto;
  }
}

// --------------------------------------------------
// Burger
// --------------------------------------------------
.main-nav-burger {
  display: none;
  position: relative;
  height: 100%;
  padding: 25px;
  margin-left: 0;
  cursor: pointer;
  background: #FBFBFF;
  border: 1px solid $alternateWhite;
  border-top: 0;

  i {
    font-size: 25px;
  }

  @media only screen and (max-width: 599px) {
    margin-left: auto;
  }
}

.has-aside .main-nav-burger {
  display: block;
}

// --------------------------------------------------
// Search
// --------------------------------------------------
.topbar-search {
  position: relative;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 599px) {
    margin-right: 0;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate3d(0, -50%, 0);

    display: inline-block;
    font-size: 32px;
    color: $colorAccent1;

    cursor: pointer;
  }

  input {
    background: rgba(255, 255, 255, .08);
    border: 1px solid $colorAccent1;
    color: $colorAccent1;
    width: 55px;
    padding: 15px 20px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.02em;
    opacity: 0;
    background: #fff;

    transition: all 0.35s ease;

    cursor: pointer;

    &:focus {
      width: 242px;
      opacity: 1;

      @media only screen and (max-width: 599px) {
        width: 165px;
      }
    }
  }
}


// --------------------------------------------------
// Mobile Burger
// --------------------------------------------------
.burger {
  display: none;
  position: relative;
  width: 32px;
  height: 50px;
  margin-left: auto;
  margin-right: 15px;
  cursor: pointer;
  transform: scale(.8);
  z-index: 999;

  @media only screen and (max-width: 989px) {
    display: block;
  }

  &:hover {

    .lines {

      &:before{
        top: -10px;
      }

      &:after{
        top: 8px;
      }
    }
  }

  &.is-active {

    .lines {
      background: transparent;

      &:before {
        top: 0;
        background: $colorAccent1;
        transform: rotate(-45deg);
      }

      &:after {
        top: -2px;
        background: $colorAccent1;
        transform: rotate(45deg);
      }
    }
  }

  .lines {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -1px;
    display: block;
    width: 100%;
    height: 2px;
    background: $colorAccent1;

    &:before,
    &:after {
      display: block;
      content: '';
      position: relative;
      top: -10px;
      width: 100%;
      height: 100%;
      background: $colorAccent1;
      transition: all 0.25s ease-out 0.1s;
    }

    &:after {
      top: 8px;
    }
  }
}


.mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  background: rgba(255, 255, 255, .95);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transform: translate3d(100%, 0, 0);

  transition: all 0.25s ease-out 0.1s;

  &.is-active {
    @media only screen and (max-width: 989px) {
      transform: translate3d(0, 0, 0);
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    display: inline-block;
    width: 100%;
    max-width: 235px;
    padding: 15px 20px;
    margin-bottom: 15px;
    color: $colorAccent1;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.035em;

    border-radius: 6px;

    @media only screen and (max-width: 699px) {
      padding: 10px 20px;
      margin-bottom: 10px;
    }

    &.is-active {
      background: #fff;
      color: $colorAccent3;
    }

    .icon {
      position: relative;
      top: 2px;
      margin-right: 7px;
      font-size: 18px;
    }
  }

  .socials {
    display: flex;

    a {
      margin: 0;
    }
  }
}
