// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts
$fontTitle: 'Roboto Slab', 'Open Sans', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontText: 'Roboto Slab', 'Open Sans', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontHand: 'Mrs Saint Delafield', 'Open Sans', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Accents Colors
$colorAccent1: #000;
$colorAccent2: #0D416B;
$colorAccent3: #C29E3F;

$colorDanger: #e74c3c;

// Blocks and Text
$dark: #000;
$grey: #bcc3cc;
$light: #919396;
$lighter: #eaecef;
$alternateWhite: #f6f8fc;


// --------------------------------------------------
// Custom Fonts
// --------------------------------------------------

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 1, 2018 */
@font-face {
  font-family: 'gt_americabold';
  src: url('../font/gt-america-standard-bold-webfont.woff2') format('woff2'),
  url('../font/gt-america-standard-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt_americamedium';
  src: url('../font/gt-america-standard-medium-webfont.woff2') format('woff2'),
  url('../font/gt-america-standard-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt_americaregular';
  src: url('../font/gt-america-standard-regular-webfont.woff2') format('woff2'),
  url('../font/gt-america-standard-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt_sectrabold';
  src: url('../font/gt-sectra-bold-webfont.woff2') format('woff2'),
  url('../font/gt-sectra-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
