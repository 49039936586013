// --------------------------------------------------
// Title
// --------------------------------------------------

b,
strong {
  font-weight: 400;
}

p {
  a {
    color: $colorAccent1;
  }

  strong {
    font-weight: 600;
  }
}

h5 {
  color: $colorAccent3;
}

.big-title {
  margin-bottom: 45px;
  color: $dark;
  font-family: $fontText;
  font-size: 41px;
  line-height: 1;
  font-weight: 400;

  @media (max-width: 767px) {
    font-size: 31px;
  }
}

.medium-title {
  color: $dark;
  font-family: $fontTitle;
  font-size: 26px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: .055em;

  &.with-child {
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }

    span {
      display: flex;
      justify-content: flex-start;
      flex-grow: 1;

      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }
  }

  strong {
    font-weight: inherit;
  }
}

.small-title {
  color: $dark;
  font-family: $fontTitle;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: .89px;

  @media screen and (min-width: 1440px) {
    font-size: 18px;
    line-height: 24px;
  }
}

// --------------------------------------------------
// Text
// --------------------------------------------------

.regular-text {
  margin-bottom: 20px;
  color: $light;
  font-family: $fontText;
  font-size: 15px;
  font-weight: 400;
  line-height: 2;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }

  strong {
    color: #6f6f6f;
    font-weight: 600;
  }

  a {
    color: $colorAccent3;
    transition: all .35s ease;

    &:hover {
      color: $colorAccent1;
    }
  }

  &.is-centered {
    width: 100%;
    font-size: 18px;
    letter-spacing: .035em;
    text-align: center;
  }
}

.text-input {
  color: $dark;
  font-family: $fontText;
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: .29px;

  @media screen and (min-width: 1440px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.small-text {
  color: $dark;
  font-family: $fontTitle;
  font-size: 11px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: .5px;

  @media screen and (min-width: 1440px) {
    font-size: 12px;
    line-height: 18px;
  }
}

.links {
  color: $colorAccent2;
  font-family: $fontTitle;
  font-size: 22px;
  font-weight: 300;

  line-height: 14px;
  letter-spacing: .29px;

  @media screen and (min-width: 1440px) {
    font-size: 24px;
    line-height: 16px;
  }
}

.pagination {
  color: $dark;
  font-family: $fontTitle;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: .5px;

}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// --------------------------------------------------
// Extra
// --------------------------------------------------

.light-typo {
  color: #fff;
}

.lightblue-typo {
  color: $colorAccent2;
}

.centered {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.separator-content-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.is-separator {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -75px;
    width: 1000%;
    height: 1px;
    background: #fff;


    transform: translate3d(-100%, -50%, 0);

    filter: blur(0);
    backface-visibility: hidden;
    @media only screen and (max-width: 699px) {
      left: -45px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -75px;
    width: 1000%;
    height: 1px;
    background: #fff;

    transform: translate3d(100%, -50%, 0);
    filter: blur(0);
    backface-visibility: hidden;

    @media only screen and (max-width: 699px) {
      right: -45px;
    }
  }
}


ul {

  li {
    line-height: 2;
  }

  ul {
    padding: 0;
    list-style-type: none;

    li {
      line-height: 2;
    }
  }
}


i, .icon, p i, p .icon {
  color: $colorAccent3;
}

i.is-danger, .icon.is-danger, p i.is-danger, p .icon.is-danger {
  color: $colorDanger;
}
