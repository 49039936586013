// --------------------------------------------------
// Footer
// --------------------------------------------------

.main-footer {
  position: relative;
  z-index: 15;
  padding-right: 55px;
  padding-left: 55px;
  background: $colorAccent3;
  color: #fff;
  font-family: $fontText;

  @media only screen and (max-width: 1349px) {
    padding-right: 45px;
    padding-left: 45px;
  }

  @media only screen and (max-width: 1099px) {
    padding-right: 25px;
    padding-left: 25px;
  }

  @media only screen and (max-width: 767px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  .socials {
    position: absolute;
    top: 40px;
    right: 0;
    font-size: 20px;

    a {
      margin-right: 0;

      @media only screen and (max-width: 1349px) {
        font-size: 20px;
      }
    }
  }
}

.footer-nav {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 40px 0;
  font-size: 16px;

  @media only screen and (max-width: 1349px) {
    padding: 45px 0 25px 0;
  }

  @media only screen and (max-width: 899px) {
    display: none;
  }

  .logo {
    padding: 0;
    margin-right: auto;

    img {
      width: 215px;

      @media only screen and (max-width: 1349px) {
        width: 155px;
      }
    }
  }

  a {
    display: inline-block;
    margin-right: 45px;

    @media only screen and (max-width: 1349px) {
      margin-right: 25px;
      font-size: 14px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.footer-copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid rgba(#fff, .1);
  font-size: 12px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    line-height: 1.3;
    text-align: center;
  }

  a {
    display: inline-block;
    padding-right: 45px;
    font-size: 14px;

    @media only screen and (max-width: 1349px) {
      padding-right: 25px;
    }

    @media only screen and (max-width: 899px) {
      display: none;

      &:nth-child(4),
      &:nth-child(5) {
        display: inline-block;
      }
    }



    &:last-child {
      padding-right: 0;
    }
  }
}
